export default function RichTextParser({ children, className }) {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    ></div>
  );
}
