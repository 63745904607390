import React from 'react';
import { useRouter } from 'next/router';

const styles = ({ type }) => {
  let style = '';
  const sizeStyle = '';
  switch (type) {
    case 'primary':
      style = 'bg-background-light text-gray-800';
      break;
    case 'transparent':
      style = 'bg-transparent text-default';
      break;
    case 'secondary':
      style = 'bg-dark-800 text-white  px-6 py-[14px]';
      break;
    case 'tertiary':
      style = 'bg-tertiary-main text-dark-800 px-4 py-3';
      break;
    case 'outline':
      style = 'bg-transparent text-dark-900 border border-dark-900 px-4 py-3';
      break;
    case 'main':
      style = 'bg-secondary-main text-dark-800 px-6 py-[14px]';
      break;
    default:
      style = 'text-secondary border-secondary';
  }
  return `${style} ${sizeStyle} focus:outline-none cursor-pointer transition-250ms md:w-fit px-[24px] py-[14px] rounded-full disabled:opacity-50 disabled:bg-slate-200 disabled:cursor-not-allowed font-medium`;
};
function Button({
  id,
  children,
  type,
  link,
  kind,
  newTab,
  router,
  onClick,
  disabled = false,
  rounded = true,
  className,
}) {
  const location = useRouter();
  const action = () => {
    if (onClick) return onClick();

    if (!link) return null;

    if (!router) {
      return window.open(link, newTab ? '_blank' : '_self');
    }

    return location.push(link);
  };

  if (!className) {
    className = '';
  }

  return React.createElement(
    'button',
    {
      id,
      type: kind || 'button',
      disabled,
      onClick: action,
      className: [className, styles({ type }), rounded && 'rounded-4xl inline-block'].join(' '),
    },
    children,
  );
}

export default Button;
