/**
 * This component was made because of the problem described in the following issue:
 * REF: https://github.com/vercel/next.js/issues/23742
 *
 * Instead of having Next.js throw an error with a stack trace that doesn't make any specific sense and,
 * provided that inspecting can also be hectic in that case, we just render a placeholder image.
 * That way it's clear & visible where to look and that something is wrong with the image.
 *
 * PS: Likely Next.js will solve the issue in future version
 */

import Image from 'next/image';

const DefaultImage = (props) => (
  <svg
    {...props}
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="Placeholder: Responsive image"
    preserveAspectRatio="xMidYMid slice"
    focusable="false"
  >
    <title>Placeholder</title>
    <rect width="100%" height="100%" fill="#868e96" />
    <text x="50%" y="50%" fill="#dee2e6" dy=".3em">
      missing image
    </text>
  </svg>
);

// TODO: Would've been cool to have propTypes documented
export default function NMImage({ src, alt, ...rest }) {
  return src ? <Image src={src} alt={alt} {...rest} /> : <DefaultImage {...rest} />;
}
