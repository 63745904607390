/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
import { config } from '@fortawesome/fontawesome-svg-core';
import SimpleReactLightbox from 'simple-react-lightbox';
import client from '../apollo-client';
import '../styles/datepicker.css';
import { ApolloProvider } from '@apollo/client';
import '../styles/globals.css';
import Navbar from '../components/Navbar';
import '@fortawesome/fontawesome-svg-core/styles.css';
import Footer from '../components/Footer';
import CheckoutContextProvider from '../context/checkout';
import 'swiper/css';
import { DEFAULT_APP_DATA } from '../graphql/queries';
import Head from 'next/head';
import { GoogleAnalytics, usePageViews, event } from 'nextjs-google-analytics';

config.autoAddCss = false;

export function reportWebVitals({ id, name, label, value }) {
  event(name, {
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
}

function MyApp({ Component, pageProps, err }) {
  usePageViews();

  return (
    <>
      <GoogleAnalytics />
      <Head>
        <title>Nature Meet</title>
      </Head>
      <ApolloProvider client={client}>
        <CheckoutContextProvider>
          <SimpleReactLightbox>
            <main className="relative">
              {pageProps.navbar && <Navbar {...pageProps.navbar} theme={pageProps?.theme} />}
              <Component {...pageProps} err={err} />
              {pageProps?.footer && (
                <Footer {...pageProps?.footer} footerPadding={pageProps?.footerPadding} />
              )}
            </main>
          </SimpleReactLightbox>
        </CheckoutContextProvider>
      </ApolloProvider>
    </>
  );
}
MyApp.getInitialProps = async () => {
  const query = await client.query({
    query: DEFAULT_APP_DATA,
  });

  return {
    pageProps: {
      navbar: query?.data?.navbar?.data?.attributes || null,
      footer: query?.data?.footer?.data?.attributes || null,
    }, // will be passed to the page component as props
  };
};

export default MyApp;
