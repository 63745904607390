import React from 'react';
import RichTextParser from './RichTextParser';

function Paragraph({ text, className, onClick, fontSizeClass }) {
  return (
    <div>
      <div
        onClick={onClick}
        className={`${className} font-sans ${fontSizeClass || 'text-base md:text-[18px]'}`}
      >
        <RichTextParser>{text}</RichTextParser>
      </div>
    </div>
  );
}

export default Paragraph;
