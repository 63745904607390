import { useState, createContext, useMemo, useCallback } from 'react';

// the default checkout data
const defaultState = {};

export const CheckoutContext = createContext(defaultState);

const CheckoutContextProvider = ({ children }) => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhoneNumber] = useState('');

  const [errors, setErrors] = useState({});

  const [event, setEventData] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [trip, setTrip] = useState(null);

  const clearErrors = useCallback(
    (key) => {
      if (!errors[key]) return;
      setErrors({
        ...errors,
        [key]: null,
      });
    },
    [errors],
  );

  const clearForm = async () => {
    await setErrors({});
    await setEventData(null);
    await setTickets([]);
    await setAddOns([]);
    await setFirstname('');
    await setLastname('');
    await setEmail('');
    await setPhoneNumber('');
  };

  const setEvent = useCallback(
    (_event) => {
      if (!_event?.id) return;
      clearErrors('event_id');
      setEventData(_event);
    },
    [clearErrors],
  );

  const setContactDetails = useCallback(
    (data) => {
      // TODO: validate email and phone number
      clearErrors('firstname');
      clearErrors('lastname');
      clearErrors('email');
      clearErrors('phone_number');
      if ('firstname' in data) setFirstname(data.firstname);
      if ('lastname' in data) setLastname(data.lastname);
      if ('email' in data) setEmail(data.email);
      if ('phone_number' in data) setPhoneNumber(data.phone_number);
    },
    [clearErrors, setFirstname, setLastname, setEmail, setPhoneNumber],
  );

  const updateEntity = useCallback((item, _quantity, array) => {
    const { id } = item;
    const quantity = Number(_quantity);

    if (!quantity || quantity < 0) {
      return array.filter((entity) => entity.id !== id);
    }

    const existingEntity = array.find((entity) => entity.id === id);

    if (existingEntity) {
      return array.map((entity) => (entity.id === id ? { ...item, quantity } : entity));
    }

    return [...array, { ...item, quantity }];
  }, []);

  const updateTicket = (ticket, quantity) => {
    const value = updateEntity(ticket, quantity, tickets);
    setTickets(value);
    clearErrors('tickets');
  };

  const updateAddOn = useCallback(
    (addOn, _quantity) => {
      const value = updateEntity(addOn, _quantity, addOns);
      setAddOns(value);
      clearErrors('add_ons');
    },
    [updateEntity, addOns, clearErrors],
  );

  const getData = () => {
    return {
      firstname,
      lastname,
      email,
      phone_number,
      event_id: event?.id,
      tickets: tickets.map((ticket) => ({ id: ticket.id, quantity: ticket.quantity })),
      add_ons: addOns.map((addOn) => ({ id: addOn.id, quantity: addOn.quantity })),
    };
  };

  const validate = useCallback(() => {
    let _errors = {};
    if (!tickets?.length) {
      _errors.tickets = 'Please add at least one ticket';
    }

    if (!event?.id) {
      _errors.event_id = 'Please select an event';
    }
    if (!firstname) {
      _errors.firstname = 'Please enter your first name';
    }

    if (!lastname) {
      _errors.lastname = 'Please enter your last name';
    }

    if (!email) {
      _errors.email = 'Please enter your email';
    }

    setErrors(_errors);
    return _errors;
  }, [setErrors, firstname, lastname, email, tickets, event]);

  const contextValue = useMemo(
    () => ({
      getData,
      updateTicket,
      setEvent,
      setTickets,
      setContactDetails,
      updateAddOn,
      event: event,
      tickets,
      addOns,
      errors,
      setErrors,
      validate,
      trip,
      setTrip,
      clearForm,
      firstname,
      lastname,
      email,
      phone_number,
    }),
    [
      event,
      setEvent,
      setContactDetails,
      updateAddOn,
      tickets,
      addOns,
      setErrors,
      errors,
      validate,
      trip,
      setTrip,
      setTickets,
      firstname,
      lastname,
      email,
      phone_number,
    ],
  );

  return <CheckoutContext.Provider value={contextValue}>{children}</CheckoutContext.Provider>;
};

export default CheckoutContextProvider;
