/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useRouter } from 'next/router';
import { Disclosure } from '@headlessui/react';
import SmallText from './SmallText';
import { faCircleChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getImageData from '../helpers/ImageHelper';
import Link from 'next/link';
import Paragraph from './Paragraph';
import NMImage from './shared/NMImage';

function Footer({ footerPadding, ...data }) {
  const location = useRouter();
  const logoUrl = getImageData(data?.logo).src;
  if (!data) return null;
  return (
    <div className={`${footerPadding ? 'pb-28 lg:pb-0' :'pb-0'} 'w-full bg-dark-900`}>
      <div
        className={`${
          location.asPath === '/checkout' ? 'hidden' : 'flex'
        } content-wrapper flex flex-col gap-12 pt-24 lg:flex-row lg:gap-28`}
      >
        <div className="h-[2.5625rem] w-[13.75rem] self-center lg:self-start">
          {logoUrl && (
            <Link href="/" className="cursor-pointer">
              <a>
                <NMImage
                  height={41}
                  width={220}
                  layout="responsive"
                  src={logoUrl}
                  alt="NatureMeetLogo"
                />
              </a>
            </Link>
          )}
        </div>

        <div className="grid grid-flow-row text-background-light lg:grid-flow-col lg:gap-x-24 xl:gap-x-36">
          {data?.items?.map((item) => (
            <Disclosure
              key={item.id}
              as="div"
              className=" border-b-[1px] border-solid border-dark-700 py-6 first:pt-0 last:border-0 last:pb-0 lg:border-0 lg:py-0"
            >
              {({ open }) => (
                <div>
                  <Paragraph className="hidden font-semibold lg:block" text={item.title} />
                  <Disclosure.Button className="flex w-full justify-between lg:hidden">
                    <SmallText className="font-semibold" text={item.title} />
                    <FontAwesomeIcon
                      size="1x"
                      icon={faCircleChevronDown}
                      className={`ml-2 text-background-light ${open ? 'rotate-180 transform' : ''}`}
                    />
                  </Disclosure.Button>
                  <div className="flex flex-col">
                    {item.links?.map((link) => (
                      <div key={link.id}>
                        <a
                         target="_blank"
                          href={link.url}
                          className="hidden pt-2 font-sans text-base font-normal leading-[22.4px] lg:block lg:text-lg lg:leading-[25.2px]" rel="noreferrer"
                        >
                          {' '}
                          {link.link_text}
                        </a>
                        <Disclosure.Panel key={link.id} className="block pt-2 lg:hidden">
                          <a
                            key={link.id}
                            href={link.url}
                            className="font-sans text-base font-normal leading-[22.4px] lg:text-lg lg:leading-[25.2px]"
                          >
                            {' '}
                            {link.link_text}
                          </a>
                        </Disclosure.Panel>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
      <div className="mt-8 py-4 text-center font-sans text-base leading-[22.4px] text-background-light lg:mt-16 lg:py-6">
        {data?.copyrightText}
      </div>
    </div>
  );
}

export default Footer;
