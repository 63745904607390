import React from 'react';

function SmallText({text, className, fontSizeClass}) {
  return (
    <div>
      <p
        className={`${className} font-sans ${fontSizeClass || 'md:text-base text-[14px] md:leading-[19.2px] leading-none'}`}>{text}</p>
    </div>
  );
}

export default SmallText;
