import {gql} from "@apollo/client";


export const slugData = `data {
  id
  attributes {
    slug
  }
}`;


export const DEFAULT_APP_DATA = gql`
    query {
        navbar {
            data {
                attributes {
                    siteTitle
                    logo {
                        data {
                            attributes {
                                mime
                                alternativeText
                                name
                                height
                                width
                                url
                                provider_metadata
                            }
                        }
                    }
                    logo_dark {
                        data {
                            attributes {
                                mime
                                alternativeText
                                name
                                height
                                width
                                url
                                provider_metadata
                            }
                        }
                    }
                    items {
                        __typename
                        ... on ComponentUiLink {
                            id
                            link_text
                            url
                            newTab
                        }
                        ... on ComponentUiButton {
                            id
                            text
                            type
                            url
                            newTab
                        }
                    }
                }
            }
        }
        footer {
            data {
                attributes {
                    logo {
                        data {
                            id
                            attributes {
                                mime
                                alternativeText
                                name
                                height
                                width
                                url
                                provider_metadata
                            }
                        }
                    }
                    copyrightText
                    items {
                        id
                        __typename
                        title
                        links {
                            id
                            newTab
                            url
                            link_text
                        }
                    }
                }
            }
        }
    }
`;



