function getImageData(image) {
  return {
    id: image?.data?.attributes?.id,
    src: image?.data?.attributes?.provider_metadata?.public_id,
    alt: image?.data?.attributes?.alternativeText,
    width: image?.data?.attributes?.width,
    height: image?.data?.attributes?.height,
    hash: image?.data?.attributes?.hash,
  };
}
export default getImageData;
