/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { Menu, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/pro-regular-svg-icons';
import MobileLogo from '../Assets/MobileLogo.svg';
import Button from './Button';
import getImageData from '../helpers/ImageHelper';
import NMImage from './shared/NMImage';

function Navbar({ ...data }) {
  const [scrolled, setScrolled] = useState(false);

  const dark = data?.theme === 'dark';

  const isTransparent = () => {
    if (window.scrollY >= 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('scroll', isTransparent);

    return () => {
      window.removeEventListener('scroll', isTransparent);
    };
  }, []);

  function NextLink({ href, children, ...rest }) {
    return (
      <Link href={href}>
        <a {...rest}>{children}</a>
      </Link>
    );
  }

  const location = useRouter();


  const isLight = !dark || scrolled;

  if (!data) return null;
  return (
    <Menu className="relative">
      <div className="relative">
        <nav className={`${scrolled ? 'bg-white' : 'bg-transparent'} fixed top-0 z-[99] w-full`}>
          <div className="w-full">
            <div className="content-wrapper flex pt-6 pb-6 items-center justify-between">
              <div className="w-full lg:w-0">
                <div>
                  <Link href="/">
                    <a className="cursor-pointer">
                      <div className="h-[28px] w-[148px] self-center lg:h-[33.696px] lg:w-[179px] lg:self-start">
                        {!isLight ? (
                          <NMImage
                            width={getImageData(data?.logo).width}
                            height={getImageData(data?.logo).height}
                            layout="responsive"
                            src={getImageData(data?.logo).src}
                            alt={getImageData(data?.logo).alt}
                          />
                        ) : (
                          <NMImage
                            width={getImageData(data?.logo_dark).width}
                            height={getImageData(data?.logo_dark).height}
                            layout="responsive"
                            src={getImageData(data?.logo_dark).src}
                            alt={getImageData(data?.logo_dark).alt}
                          />
                        )}
                      </div>
                    </a>
                  </Link>
                </div>
              </div>
              <Menu.Button className="pt-1.5 lg:hidden">
                <div>
                  <FontAwesomeIcon
                    icon={faBars}
                    className={`${isLight ? 'text-dark-800' : 'text-white'} text-3xl `}
                  />
                </div>
              </Menu.Button>
              <div className="hidden lg:block">
                <div className="flex items-baseline text-base font-normal">
                  {data?.items?.map((item) => (
                    <div key={item.id}>
                      <Link href={item.url}>
                        <a className={` ${location.asPath === '/checkout' ? 'hidden' : 'block'}`}>
                          <div
                            className={`${
                              isLight ? 'text-dark-800' : 'text-white'
                            } font-medium font-poppins cursor-pointer py-2 pl-8 leading-none`}
                          >
                            {item.link_text}
                          </div>
                        </a>
                      </Link>
                    </div>
                  ))}
                  <Link
                    href={
                      data.items?.find((item) => item.__typename === 'ComponentUiButton')?.url || ''
                    } 
                  >
                    <a target="_blank" className="font-medium font-poppins cursor-pointer">
                      <Button className="leading-none" type="primary">
                        <span>
                          {data.items?.find((item) => item.__typename === 'ComponentUiButton').text}
                        </span>
                      </Button>
                    </a>
                  </Link>
                </div>
              </div>
            </div>

            <Transition
              enter="transition duration-500 ease-in-out"
              enterFrom="transform translate-x-full"
              enterTo="transform translate-x-0"
              leave="transition duration-500 ease-in-out"
              leaveFrom="transform translate-x-0"
              leaveTo="transform translate-x-full"
              className="lg:w-86 side-menu-width absolute top-0 right-0 w-full outline-none"
            >
              <Menu.Items className="outline-none">
                <div className="bg-background-light outline-none">
                  <div className="text-default relative mx-auto flex h-screen w-11/12 flex-col pt-8 outline-none">
                    <div className="flex flex-none cursor-pointer items-center">
                      <div className="relative flex">
                        <Menu.Item>
                          <MobileLogo className="text-default h-4.5" />
                        </Menu.Item>
                      </div>
                      <div className="ml-auto md:ml-auto lg:mr-14">
                        <Menu.Item>
                          <FontAwesomeIcon icon={faXmark} className="text-3xl outline-none" />
                        </Menu.Item>
                      </div>
                    </div>

                    <div>
                      <div className="font-poppins flex flex-col items-center pt-44 font-semibold text-dark-800">
                        {data?.items?.map((item) => (
                          <Menu.Item as={NextLink} href={item.url} key={item.id} className="flex flex-col gap-y-8">
                            
                            <span className="cursor-pointer pt-8 font-poppins text-xl leading-none">
                            {item.link_text}
                            </span>
                            
                          </Menu.Item>
                        ))}

                        <Menu.Item as={NextLink} target="_blank" href={data.items?.find((item) => item.__typename === 'ComponentUiButton')
                                ?.url}>
                          <Button className="leading-[26px] font-poppins cursor-pointer text-xl px-[47.5px]" type="secondary">
                          
                            <span className="font-semibold">
                              {
                                data.items?.find(
                                  (item) => item.__typename === 'ComponentUiButton',
                                ).text
                              }
                            </span>
                          </Button>
                        </Menu.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </nav>
      </div>
    </Menu>
  );
}
export default Navbar;
